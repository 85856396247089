<template>
  <div ref="root" class="FAQList__container">
    <FormFilter
      :config="{ ...configRef, listCategory: getListCategory() }"
      :allow-reset="true"
      :allow-only-initial-ids="true"
      :show-filter-on-no-result="true"
      @filtered="displayResults"
      @has-results="displayPagination"
      @loading-change="setFormFilterLoading"
    />
    <Accordion
      v-if="!formFilterLoading && displayedResults && displayedResults.length"
      ref="accordion"
      :item-collection="displayedResults"
      :initial-open-accordion-items="anchorLink ? [getInitialOpenAccordionItemId()] : []"
      :multiple-active="true"
      item-id-prefix="faq"
    />
    <Pagination
      v-show="
        (configRef.loadInitialData || hasResults) &&
        config.pagination.resultsPerPage < fullAmountOfResults
      "
      :config="configRef.pagination"
      :results="results.length"
      @update="displayPaginatedResults"
    />
    <Modal :is-modal-open="videoUrl !== null" :is-video="true" @close-modal="videoUrl = null">
      <iframe
        :data-src="videoUrl"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      />
    </Modal>
  </div>
</template>

<script setup>
import FormFilter from '../FormFilter/FormFilter.vue'
import Accordion from '../Accordion/Accordion.vue'
import Pagination from '../Pagination/Pagination.vue'
import Modal from '../Modal/Modal.vue'
import { ListCategory } from '../../globals/constants/formFilter'
import { onBeforeMount, onUnmounted, onUpdated, ref } from 'vue'

const props = defineProps({
  config: {
    type: Object,
    default: () => {}
  }
})

const configRef = ref(props.config)
const anchorLink = ref(null)
const results = ref([])
const displayRange = ref([0, 11])
const displayedResults = ref([])
const hasResults = ref(false)
const formFilterLoading = ref(false)
const fullAmountOfResults = ref(999999)
const videoUrl = ref(null)
const videoLinkClass = ref('faqVideoLink')
const accordion = ref(null)
const root = ref(null)

const showFaqVideoModal = (e) => {
  const { target } = e

  if (!target) {
    return
  }

  if (target.classList.contains(videoLinkClass.value)) {
    e.preventDefault()
    videoUrl.value = target.getAttribute('href')
    return
  }

  const { tagName, parentElement } = target

  if (
    tagName.toUpperCase() === 'IMG' &&
    parentElement.classList.contains(videoLinkClass.value).value
  ) {
    e.preventDefault()
    videoUrl.value = parentElement.getAttribute('href')
  }
}

const setFormFilterLoading = (isLoading) => {
  formFilterLoading.value = isLoading
}

const displayPagination = (hasNewResults) => {
  hasResults.value = hasNewResults
}

const displayResults = (newResults, preventResetAccordion) => {
  if (!preventResetAccordion) {
    resetAccordion()
  }
  results.value = newResults
  displayedResults.value = []
  for (let i = 0; i < results.value.length; i++) {
    if (i >= displayRange.value[0] && i <= displayRange.value[1]) {
      displayedResults.value.push(results.value[i])
    } else if (i > displayRange.value[1]) {
      break
    }
  }
}

const resetAccordion = () => {
  accordion.value !== null && accordion.value.reset()
}

const displayPaginatedResults = (newDisplayRange) => {
  displayRange.value = newDisplayRange
  displayResults(results.value, true)
}

const getListCategory = () => {
  return ListCategory.FAQ
}

const modifyConfigIfAnchorLink = () => {
  configRef.value.pagination.resultsSelect.initialSelectOption =
    configRef.value.pagination.resultsSelect.selectOptions[
      configRef.value.pagination.resultsSelect.selectOptions.length - 1
    ]
  configRef.value.pagination.resultsPerPage = fullAmountOfResults.value
  configRef.value.loadInitialData = true
  displayRange.value = [0, fullAmountOfResults.value]
}

const scrollToFaqItem = () => {
  const element = root.value.querySelector(anchorLink.value)

  if (element) {
    element.scrollIntoView({ behavior: 'smooth' })
    anchorLink.value = null
  }
}

const getInitialOpenAccordionItemId = () => {
  const items = displayedResults.value.filter(
    (item) => item.id === parseInt(anchorLink.value.replace(/#faq/, ''), 10)
  )
  if (items.length === 1) {
    return items[0].id
  }
}

onUpdated(() => {
  if (anchorLink.value) {
    scrollToFaqItem()
  }
})

onUnmounted(() => {
  document.removeEventListener('click', showFaqVideoModal)
})

onBeforeMount(() => {
  anchorLink.value = location.hash
  document.addEventListener('click', showFaqVideoModal)
  /**
   * check if there is an anchor link and it starts with a "#faq",
   * if there is an anchor link and it starts not with "#faq" show an error.
   */
  if (anchorLink.value.toString().startsWith('#faq')) {
    modifyConfigIfAnchorLink()
  } else if (anchorLink.value && !anchorLink.value.toString().startsWith('#faq')) {
    console.error(
      "This is not a valid FAQ anchor link (hash in URL). The FAQ anchor link should start with '#faq'."
    )
    anchorLink.value = null
  }
})
</script>

<style module lang="scss">
@import './FAQList.scss';
</style>
