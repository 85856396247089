<template>
  <div
    :class="$style.container"
    :style="bgImgUrl ? { backgroundImage: `url(${bgImgUrl})` } : undefined"
    @mouseenter="autoplayStop"
    @mouseleave="autoplayStart"
  >
    <swiper
      class="mySwiper"
      :autoplay="autoplaySettings"
      :breakpoints="breakpoints"
      effect="coverflow"
      :modules="modules"
      :pagination="paginationSettings"
      :centered-slides="true"
      :loop="true"
      :rewind="true"
      :slide-to-clicked-slide="true"
      :space-between="10"
      @swiper="onSwiper"
    >
      <swiper-slide v-for="(slide, index) in slides" :key="index">
        <CarouselSlide :slide="slide" @open-modal="openModal" />
      </swiper-slide>
    </swiper>
    <div
      v-if="slides.length > 1"
      :class="['swiper-button-prev', $style.singleSlide]"
      @click="prevSlide"
    >
      <ChevronLeft />
    </div>
    <div
      v-if="slides.length > 1"
      :class="['swiper-button-next', $style.singleSlide]"
      @click="nextSlide"
    >
      <ChevronRight />
    </div>
  </div>
  <Modal
    :is-not-you-tube="!isYouTube"
    :is-video="true"
    :is-modal-open="isModalOpen"
    @close-modal="closeModal"
  >
    <iframe
      v-if="isYouTube"
      :data-src="videoUrl"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    />
    <video v-else ref="video" muted autoplay loop controls>
      <source :src="videoUrl" type="video/mp4" />
    </video>
  </Modal>
</template>
<script setup>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue'
// import required modules
import { Autoplay, EffectCoverflow, Pagination } from 'swiper/modules'
import { ref } from 'vue'
import ChevronLeft from '../../assets/svg/ChevronLeft.vue'
import ChevronRight from '../../assets/svg/ChevronRight.vue'
import Modal from '../Modal/Modal.vue'
import CarouselSlide from './CarouselSlide.vue'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

const props = defineProps({
  uid: {
    type: String
  },
  slides: {
    type: Array,
    default: () => []
  },
  autoplay: {
    type: Boolean,
    default: false
  },
  bgImgUrl: {
    type: String
  },
  hidePagination: {
    type: Boolean,
    default: null
  }
})

const isModalOpen = ref(false)
const isYouTube = ref(false)
const videoUrl = ref(null)
const video = ref(null)
const swiperInstance = ref(null)

const modules = [Autoplay, EffectCoverflow, Pagination]

const autoplaySettings = props.autoplay
  ? {
      delay: 4000
    }
  : false

const paginationSettings = props.hidePagination
  ? {
      enabled: false
    }
  : {
      enabled: true,
      clickable: true
    }

const breakpoints = {
  0: {
    slidesPerView: 1
  },
  480: {
    slidesPerView: 2
  },
  768: {
    slidesPerView: 3
  },
  1024: {
    slidesPerView: 4
  }
}

const openModal = (slideVideo, slideIsYouTube) => {
  videoUrl.value = slideVideo
  isYouTube.value = slideIsYouTube
  isModalOpen.value = true
  if (video.value !== null && !isYouTube.value) {
    video.value.load()
  }
}

const closeModal = () => {
  if (video.value !== null) {
    video.value.pause()
  }
  isModalOpen.value = false
}

const onSwiper = (currentSwiperInstance) => {
  swiperInstance.value = currentSwiperInstance
}

const prevSlide = () => {
  if (!swiperInstance.value) return
  swiperInstance.value.slidePrev()
}
const nextSlide = () => {
  if (!swiperInstance.value) return
  swiperInstance.value.slideNext()
}

const autoplayStart = () => {
  if (!swiperInstance.value.autoplay) return
  swiperInstance.value.autoplay.start()
}

const autoplayStop = () => {
  if (!swiperInstance.value.autoplay) return
  swiperInstance.value.autoplay.stop()
}
</script>

<style module lang="scss">
@import './RotatingCarousel.scss';
</style>
