<template>
  <div>
    <div v-if="!consentGiven" :class="$style.consent">
      <h2>{{ dictionary.consentHeading }}</h2>
      <div v-html="vendorConsentText" />
      <Button
        is-close
        :class="$style.consentButton"
        :text="dictionary.consentButtonLabel"
        @close="handleContentButton"
      />
    </div>
    <template v-if="consentGiven">
      <div :class="$style.container">
       <div ref="map" :class="$style.map" />
     </div>
    </template>
  </div>
</template>


<script setup>
//* prevent Eslint warnings
/*global google*/
import { ref, onMounted } from 'vue'
import Button from '../Button/Button.vue'
import { getVendorConsent, registerConsentListeners } from '../../globals/consentmanager'
import cmpVendorIDs from '../../globals/constants/cmpVendorIDs'

const props = defineProps({
  config: {
    type: Object,
    default: () => {}
  }
})

const vendor = cmpVendorIDs.GOOGLEMAPS
const consentGiven = ref(window.Cookiebot ? true : getVendorConsent(vendor.id))
const dictionary = window.dictionary
const vendorConsentText = dictionary.consentText.replaceAll("###VENDOR_NAME###", vendor.name)

const apiKey = 'AIzaSyCdM1m57HfyyDYNneMFZ_e8spr1PeSOpjM'
const address = [
  props.config.street,
  props.config.zip,
  props.config.city,
  props.config.country
].join(',')
const infoWindow = ref(null)
const map = ref(null)
const mapOptions = ref({
  zoom: 15,
  disableDefaultUI: false,
  scaleControl: false,
  streetViewControl: false,
  fullscreenControl: false,
  mapTypeControl: false,
  mapTypeId: null
})

const callInit = () => {
  setTimeout(() => {
    if (typeof google === 'object' && typeof google.maps === 'object') {
      initGMaps()
    } else {
      callInit()
    }
  }, 200)
}

const checkVendorConsent = () => {
  consentGiven.value = getVendorConsent(vendor.id)
  checkConsent()
}

const handleContentButton = () => {
  if (window.cmpConsentType === 'consentmanager') {
    window.__cmp?.('setVendorConsent', [vendor.id, 1], () => {
      consentGiven.value = getVendorConsent(vendor.id)
    })
  } else {
    window.Cookiebot.onaccept = () => {
      consentGiven.value = true
    }
  }
  checkConsent()
}

const checkConsent = () => {
  if (consentGiven.value) {
    callInit()
  }
}

const initGMaps = () => {
  mapOptions.value.mapTypeId = google.maps.MapTypeId.HYBRID
  const config = props.config
  let directionsURL = ''
  let contentString =
    '<style type="text/css">img[src*="gstatic.com/"], img[src*="googleapis.com/"] { max-width: 99999px; };</style><div style="width:260px; height:100px;">'
  contentString +=
    '<table border="0" cellpadding="0" cellspacing="0" width="260" style="margin-top: 5px;><tr valign="top"><td width="50" style="padding: 0px; border: none;"><img src="' +
    config.logo +
    '" width="50" border="0"></td><td width="5" style="padding: 0px; border: none;"></td><td width="205" style="padding: 0px; border: none; font-family: Arial, Helvetica; font-size: 11px; font-weight: normal; line-height: 1.4;">'
  contentString += '<b>' + config.company + '</b><br/>'
  if (config.addInfo !== '') contentString += config.addInfo + '<br/>'
  contentString += config.street + '<br />' + config.zip + ' ' + config.city + '<br /><br />'
  if (config.directionsText !== '') {
    if (
      typeof config.latitude !== 'undefined' &&
      config.latitude !== '' &&
      typeof config.longitude !== 'undefined' &&
      config.longitude !== ''
    ) {
      directionsURL =
        'http://maps.google.com/?q=' +
        encodeURIComponent(config.latitude + ',' + config.longitude) +
        '&hl=' +
        config.language
    } else {
      directionsURL =
        'http://maps.google.com/?daddr=' +
        encodeURIComponent(
          config.street + ',' + config.zip + ' ' + config.city + ',' + config.country
        ) +
        '&hl=' +
        config.language
    }

    contentString +=
      '<b><a href="' + directionsURL + '" target="_blank">' + config.directionsText + '</a></b>'
  }
  contentString += '</td></tr></table></div>'

  infoWindow.value = new google.maps.InfoWindow({
    content: contentString,
    maxWidth: 300
  })

  map.value = new google.maps.Map(map.value, mapOptions.value)

  if (
    typeof config.latitude !== 'undefined' &&
    config.latitude !== '' &&
    typeof config.longitude !== 'undefined' &&
    config.longitude !== ''
  ) {
    const newLatLng = new google.maps.LatLng(config.latitude, config.longitude)

    map.value.setCenter(newLatLng)

    const marker = new google.maps.Marker({
      position: newLatLng,
      map: map.value,
      icon: config.marker,
      title: config.company,
      flat: true
    })

    google.maps.event.addListener(marker, 'click', () => {
      infoWindow.value.open(map.value, marker)
    })

    infoWindow.value.open(map.value, marker)
  } else {
    const geocoder = new google.maps.Geocoder()
    const geoReq = {
      address: address,
      language: config.language,
      country: config.ccTLD
    }

    geocoder.geocode(geoReq, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK && results.length) {
        if (status !== google.maps.GeocoderStatus.ZERO_RESULTS) {
          map.value.setCenter(results[0].geometry.location)

          const marker = new google.maps.Marker({
            position: results[0].geometry.location,
            map: map.value,
            icon: config.marker,
            title: config.company,
            flat: true
          })

          google.maps.event.addListener(marker, 'click', () => {
            infoWindow.value.open(map.value, marker)
          })

          infoWindow.value.open(map.value, marker)
        }
      }
    })
  }
}

onMounted(() => {
  window.cmpConsentType === 'consentmanager' ? (registerConsentListeners(checkVendorConsent), window.setTimeout(checkVendorConsent, 1500)) : checkConsent();

  if (typeof google === 'undefined' || !window.googleApiInHead) {
    window.googleApiInHead = true
    const script = document.createElement('script')
    if (props.config.language) {
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&language=${props.config.language}`
    } else {
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}`
    }
    script.setAttribute('async', '')
    script.setAttribute('defer', '')
    script.onload = callInit
    document.head.appendChild(script)
  } else if (window.googleApiInHead) {
    callInit()
  }
})
</script>

<style module lang="scss">
@import './GoogleMaps.scss';
</style>
