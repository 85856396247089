<template>
  <nav :class="$style.drawer">
    <Slide right disable-outside-click no-overlay>
      <ul v-if="!isShop && !isMicrosite" :class="$style.primaryNav">
        <li v-for="elm in primaryNav" :key="elm.title + elm.id">
          <a :class="{ MobileMenu__active: activeNodes[0] == elm.id }" :href="elm.url || '#'">{{
            elm.title
          }}</a>
          <MobileMenuAccordion
            v-for="subElm in elm.subNav"
            :key="subElm.title + subElm.id"
            :title="subElm.title"
          >
            <ul
              v-for="column in subElm.subNav"
              :key="column.title + column.id"
              :class="$style.subNav"
            >
              <span
                :class="{ [$style.MobileMenu__subNav_notitle]: !column.title }"
                v-html="column.title"
              />
              <li v-for="link in column.subNav" :key="link.title + link.id">
                <a :href="link.url || '#'" v-html="link.title" />
              </li>
            </ul>
            <span :class="$style.ctas">
              <a
                v-for="(cta, index) in subElm.ctas"
                :key="index"
                :href="cta.url"
                :target="cta.target"
                :class="$style.cta"
                :style="{
                  backgroundColor: cta.colorCode,
                  '--color-hover': cta.colorCodeHover
                }"
              >
                {{ cta.linkText }}
              </a>
            </span>
          </MobileMenuAccordion>
        </li>
      </ul>
      <ul v-if="isShop || isMicrosite" :class="$style.primaryNav">
        <li v-for="elm in primaryNav" :key="elm.title + elm.id">
          <a
            :class="{ MobileMenu__active: activeNodes[0] == elm.id }"
            :href="elm.url || '#'"
            @click="preventDefaultIfNeeded($event, elm.url)"
            v-html="elm.title"
          />
          <ul :class="$style.subNav">
            <li v-for="link in elm.subNav" :key="link.title + link.id">
              <a :href="link.url || '#'" v-html="link.title" />
            </li>
          </ul>
        </li>
      </ul>
      <ul :class="$style.secondaryNav">
        <li v-for="elm in secondaryNav" :key="elm.title + elm.id">
          <a
            :class="{ MobileMenu__active: activeNodes[0] == elm.id }"
            :href="elm.url || '#'"
            v-html="elm.title"
          />
          <ul :class="$style.subNav">
            <li v-for="link in elm.subNav" :key="link.title + link.id">
              <a :href="link.url || '#'" v-html="link.title" />
            </li>
          </ul>
        </li>
      </ul>
    </Slide>
  </nav>
</template>

<script setup>
import MobileMenuAccordion from '../MobileMenuAccordion/MobileMenuAccordion.vue'
import { Slide } from 'vue3-burger-menu'

const props = defineProps({
  isMicrosite: {
    type: Boolean,
    default: false
  },
  isShop: {
    type: Boolean,
    default: false
  },
  primaryNav: {
    type: Array,
    default: () => []
  },
  secondaryNav: {
    type: Array,
    default: () => []
  },
  activeNodes: {
    type: Array,
    default: () => []
  }
})

const preventDefaultIfNeeded = (event, url) => {
  if (props.isMicrosite && url.includes('#')) {
    event.preventDefault()
    scrollTo(url)
    return false
  }
  return true
}

const scrollTo = (url) => {
  const id = url.split('#')[1]
  const element = document.getElementById(id)
  if (element) {
    element.style.scrollMargin = '-20px'
    element.scrollIntoView({ behavior: 'smooth' })
  }
}
</script>

<style module lang="scss">
@import './MobileMenu.scss';
</style>
