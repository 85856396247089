<template>
  <div class="accordion">
    <div :class="{ card: true, 'card-active': isActive }">
      <div class="card-header" style="user-select: none" @click.prevent="handleHeaderClick">
        <p class="card-header-title">
          <span v-html="title" />
        </p>
        <p class="card-header-icon">
          <span
            :class="{
              icon: true,
              [$style.isActive]: isActive
            }"
          >
            <span :class="$style.icon" />
          </span>
        </p>
      </div>
      <div class="accordion-body">
        <div
          :class="{
            [$style.content]: true,
            'card-content': true
          }"
          :style="{
            maxHeight: `${isActive ? maxHeight : 0}px`
          }"
        >
          <div ref="content">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'

defineProps({
  title: {
    type: String,
    default: undefined
  }
})

const isActive = ref(false)
const content = ref(null)
const maxHeight = ref(0)

const handleHeaderClick = () => {
  if (!isActive.value) {
    maxHeight.value = content.value.clientHeight
  }

  isActive.value = !isActive.value
}
</script>

<style module lang="scss">
@import './MobileMenuAccordion.scss';
</style>
