<template>
  <div>
    <div v-if="!consentGiven" :class="$style.consent">
      <h2>{{ dictionary.consentHeading }}</h2>
      <div v-html="vendorConsentText" />
      <Button
        is-close
        :class="$style.consentButton"
        :text="dictionary.consentButtonLabel"
        @close="handleContentButton"
      />
    </div>
    <template v-if="consentGiven">
      <div :id="containerId" />
      <ReCaptcha v-if="config.useRecaptcha" :execute-switch="executeSwitch" :submit="getToken" />
    </template>
  </div>
</template>

<script setup>
import { isEdge, isIE } from 'mobile-device-detect'
import { onMounted, onUnmounted, onUpdated, ref } from 'vue'
import { getVendorConsent, registerConsentListeners  } from '../../globals/consentmanager'
import cmpVendorIDs from '../../globals/constants/cmpVendorIDs'
import { MwfFormNamesAndIds, MwfFormPrefixes } from '../../globals/constants/mwf'
import { getMarketingConsent } from '../../globals/cookiebot'
import * as Storage from '../../shared/Storage'
import Button from '../Button/Button.vue'
import ReCaptcha from '../ReCaptcha/ReCaptcha.vue'

const props = defineProps({
  config: {
    type: Object,
    default: () => {}
  }
})

const vendor = cmpVendorIDs.GRECAPTCHA
const htmlElement = ref(null)
const containerId = ref(null)
const executeSwitch = ref(null)
const mwfLabelCollection = ref([])
const mwfKeys = ref([])
const consentGiven = ref(window.Cookiebot ? getMarketingConsent() : getVendorConsent(vendor.id))

const dictionary = window.dictionary
const vendorConsentText = dictionary.consentText.replaceAll("###VENDOR_NAME###", vendor.name)

const checkVendorConsent = () => {
  consentGiven.value = getVendorConsent(vendor.id)
  checkConsent()
}

const handleContentButton = () => {
  if (window.cmpConsentType === 'consentmanager') {
    window.__cmp?.('setVendorConsent', [vendor.id, 1], () => {
      consentGiven.value = getVendorConsent(vendor.id)
    })
  } else {
    window.Cookiebot.onaccept = () => {
      consentGiven.value = getMarketingConsent()
    }
    checkConsent()
    window.Cookiebot.show()
  }
}

const checkConsent = () => {
  if (consentGiven.value) {
    init()
  }
}

const getToken = (token) => {
  const tokenField = document.getElementById(MwfFormNamesAndIds.TOKEN)

  if (tokenField) {
    tokenField.value = token
  }
}

const wrapFields = () => {
  mwfLabelCollection.value = []
  mwfKeys.value = []

  let fields = document.querySelectorAll('.mwf-form .mwf-field')

  for (let i in fields) {
    let field = fields[i]
    let children = field.children

    if (!children) continue

    let wrapper = document.createElement('div')
    wrapper.classList.add('mwf-wrapper')

    while (children.length > 0) {
      wrapper.appendChild(children[0])
    }

    field.appendChild(wrapper)

    setMWFLabelsAndKeys(field)
  }
}

const { currentCountry, currentLanguage } = window

const initHiddenFields = (form) => {
  if (currentCountry) {
    let input = document.createElement('input')
    input.type = 'hidden'
    input.value = currentCountry
    input.name = MwfFormNamesAndIds.COUNTRY
    form.appendChild(input)
  }

  if (window.location.host && window.location.pathname) {
    let input = document.createElement('input')
    input.type = 'hidden'
    input.value = window.location.host + window.location.pathname
    input.name = MwfFormNamesAndIds.PAGE_URL
    form.appendChild(input)
  }

  if (window.location.hostname) {
    let input = document.createElement('input')
    input.type = 'hidden'
    input.value = window.location.hostname
    input.name = MwfFormNamesAndIds.DOMAIN
    form.appendChild(input)
  }

  if (currentLanguage) {
    let input = document.createElement('input')
    input.type = 'hidden'
    input.value = currentLanguage
    input.name = MwfFormNamesAndIds.LANGUAGE
    form.appendChild(input)
  }

  if (props.config.useRecaptcha) {
    let input = document.createElement('input')
    input.type = 'hidden'
    input.value = '' // Is set by getToken method
    input.name = 'token'
    input.id = MwfFormNamesAndIds.TOKEN
    form.appendChild(input)
  }

  if (props.config.mailData) {
    for (const [key, value] of Object.entries(props.config.mailData)) {
      let input = document.createElement('input')
      input.type = 'hidden'
      input.value = value
      input.name = `${MwfFormPrefixes.MAIL_DATA}${key}`
      input.id = `${MwfFormPrefixes.MAIL_DATA}${key}`
      form.appendChild(input)
    }
  }

  if (mwfLabelCollection.value && mwfLabelCollection.value.length) {
    for (const labelObj of mwfLabelCollection.value) {
      let input = document.createElement('input')
      input.type = 'hidden'
      input.value = labelObj.label
      input.name = `${MwfFormPrefixes.FORM_LABEL}${labelObj.key}`
      input.id = `${MwfFormPrefixes.FORM_LABEL}${labelObj.key}`
      form.appendChild(input)
    }
  }

  if (mwfKeys.value && mwfKeys.value.length) {
    let input = document.createElement('input')
    input.type = 'hidden'
    input.value = mwfKeys.value.join(',')
    input.name = MwfFormNamesAndIds.FORM_ELEMENT_SEQUENCE
    input.id = MwfFormNamesAndIds.FORM_ELEMENT_SEQUENCE
    form.appendChild(input)
  }

  //Form-ID
  let input = document.createElement('input')
  input.type = 'hidden'
  input.value = form.dataset['mwfId']
  input.name = MwfFormNamesAndIds.FORM_ID
  form.appendChild(input)
}

const initRequired = () => {
  let i
  const span = htmlElement.value.getElementsByClassName('mwf-required')

  for (i = 0; i < span.length; i++) {
    span[i].parentElement.classList.add('mwf-label-required')
  }
}

const initErrors = () => {
  // highlight invalid input
  let i, j
  const p = htmlElement.value.getElementsByClassName('mwf-error')

  for (i = 0; i < p.length; i++) {
    let inputs = p[i].parentElement.querySelectorAll('input,textarea')

    for (j = 0; j < inputs.length; j++) {
      inputs[j].classList.add('mwf-invalid-input')
    }
  }
}

const initSelects = () => {
  const select = htmlElement.value.getElementsByClassName('mwf-select')

  for (let i = 0; i < select.length; i++) {
    // added currentCountry as preselected value if we had an country except Corporate Website (EN)
    if (
      (currentCountry != 'DE' || (currentCountry == 'DE' && currentLanguage != 'EN')) &&
      select[i].hasAttribute('data-mwf-datasource')
    ) {
      if (
        select[i].getAttribute('data-mwf-datasource').includes('"datasource":"countries"') &&
        !select[i]
          .getAttribute('data-mwf-datasource')
          .includes('"preselected" : "' + currentCountry + '"')
      ) {
        select[i].setAttribute(
          'data-mwf-datasource',
          select[i]
            .getAttribute('data-mwf-datasource')
            .replace(/preselected" : ".*",/g, 'preselected" : "' + currentCountry + '",')
        )
      }
    }

    if (select[i].classList.contains('mwf-select-hide')) continue

    const selElm = select[i]
    selElm.classList.add('mwf-select-hide')

    const parentElm = selElm.parentElement
    parentElm.classList.add('FormSelect__container')
    parentElm.insertBefore(parentElm.previousElementSibling, selElm)

    // For each element, create a new DIV that will act as the selected item
    const customInput = document.createElement('div')
    customInput.setAttribute('class', 'FormSelect__input')

    const defaultVal =
      selElm.options[selElm.selectedIndex] &&
      select[i].getAttribute('data-mwf-datasource-initValue') === null
        ? selElm.options[selElm.selectedIndex].innerHTML
        : select[i].getAttribute('data-mwf-datasource-initValue')

    customInput.innerHTML =
      '<span>' + defaultVal + '</span><span class="FormSelect__triangle"></span>'

    parentElm.appendChild(customInput)

    // create option list within div
    const optionsContainer = document.createElement('div')
    optionsContainer.setAttribute('class', 'FormSelect__optionsContainer mwf-select-hide')
    const ul = document.createElement('ul')
    ul.setAttribute('class', 'FormSelect__options')
    createOptions(selElm, ul)
    optionsContainer.appendChild(ul)
    parentElm.appendChild(optionsContainer)

    // toggle option list
    customInput.addEventListener('click', eventToggleCustomSelect)
  }

  // close all open selects but not current
  window.addEventListener('click', eventCloseCustomSelects)
}

const updateSelects = (elem) => {
  let parent = elem.parentElement
  let optionsContainer = parent.getElementsByClassName('FormSelect__options')[0]

  while (optionsContainer.firstChild) {
    optionsContainer.removeChild(optionsContainer.lastChild)
  }

  // added currentCountry as preselected value if we had an country except Corporate Website (EN)
  for (var j = 0; j < elem.options.length; j++) {
    if (
      elem.options[j].getAttribute('data-mwf-value') == currentCountry &&
      (currentCountry != 'DE' || (currentCountry == 'DE' && currentLanguage != 'EN'))
    ) {
      elem.options[j].setAttribute('selected', 'selected')
    }
  }

  let defaultVal =
    elem.options[elem.selectedIndex] && elem.getAttribute('data-mwf-datasource-initValue') === null
      ? elem.options[elem.selectedIndex].innerHTML
      : elem.getAttribute('data-mwf-datasource-initValue')

  parent.querySelectorAll('.FormSelect__input span')[0].innerHTML = defaultVal

  createOptions(elem, optionsContainer)
}

const createOptions = (select, ul) => {
  let options = select.options

  for (let j = 0; j < options.length; j++) {
    // added currentCountry as preselected value if we had an country except Corporate Website (EN)
    if (
      select.options[j].getAttribute('data-mwf-value') == currentCountry &&
      (currentCountry != 'DE' || (currentCountry == 'DE' && currentLanguage != 'EN'))
    ) {
      select.options[j].setAttribute('selected', 'selected')
    }
    // create a new li for each option as option item
    let addClass = j === select.selectedIndex ? ' class="FormSelect__highlighted"' : ''
    let li = document.createElement('li')
    li.setAttribute('class', 'FormSelect__option')
    li.innerHTML = '<span' + addClass + '>' + select.options[j].innerHTML + '</span>'

    li.addEventListener('click', function () {
      // update input select and mark li as selected
      let i, k, parentSelect, cstmInput, optionSpan, highlighted

      parentSelect = this.parentNode.parentNode.parentNode.getElementsByTagName('select')[0]
      cstmInput = this.parentNode.parentNode.previousSibling

      for (i = 0; i < parentSelect.length; i++) {
        optionSpan = this.getElementsByTagName('span')[0].innerHTML

        if (parentSelect.options[i].innerHTML === optionSpan) {
          parentSelect.selectedIndex = i
          cstmInput.getElementsByTagName('span')[0].innerHTML = optionSpan
          highlighted = this.parentNode.getElementsByClassName('FormSelect__highlighted')

          for (k = 0; k < highlighted.length; k++) {
            highlighted[k].removeAttribute('class')
          }

          this.getElementsByTagName('span')[0].setAttribute('class', 'FormSelect__highlighted')

          break
        }
      }

      cstmInput.click()
    })

    ul.appendChild(li)
  }
}

const eventToggleCustomSelect = (e) => {
  // close option list
  e.stopPropagation()
  eventCloseCustomSelects(e.currentTarget)
  e.currentTarget.nextSibling.classList.toggle('mwf-select-hide')
  e.currentTarget.parentElement.classList.toggle('FormSelect__active')
}

const eventCloseCustomSelects = (elmnt) => {
  // closes all other selects
  let x,
    y,
    i,
    arrNo = []

  x = htmlElement.value.getElementsByClassName('FormSelect__optionsContainer')
  y = htmlElement.value.getElementsByClassName('FormSelect__input')

  for (i = 0; i < y.length; i++) {
    if (elmnt === y[i]) {
      arrNo.push(i)
    } else {
      y[i].parentElement.classList.remove('FormSelect__active')
    }
  }
  for (i = 0; i < x.length; i++) {
    if (arrNo.indexOf(i)) {
      x[i].classList.add('mwf-select-hide')
    }
  }
}

const initEventFocusInput = () => {
  let elms, parent, ps, i

  elms = htmlElement.value.getElementsByClassName('mwf-text')

  for (i = 0; i < elms.length; i++) {
    parent = elms[i].parentElement
    ps = parent.getElementsByClassName('mwf-hint')[0]

    if (ps && !ps.classList.contains('mwf-is-initialized')) {
      ps.addEventListener('click', function (e) {
        let element = e.currentTarget.parentElement.getElementsByClassName('mwf-text')[0]

        if (isIE && !isEdge) {
          element.classList.add('mwf-focused')
        }

        element.focus()
      })
      if (isIE && !isEdge) {
        ps.parentElement
          .getElementsByClassName('mwf-text')[0]
          .addEventListener('blur', function (e) {
            e.currentTarget.classList.remove('mwf-focused')
          })
      }

      ps.classList.add('mwf-is-initialized')
    }
  }
}

const initEmptySearchForm = (form) => {
  let textarea = form.querySelector('textarea[name="message"]')

  if (textarea) {
    let failedSearchRequests = Storage.getLocalStorage('failedSearchRequests', true)

    if (
      failedSearchRequests &&
      failedSearchRequests.terms &&
      failedSearchRequests.terms.length > 0
    ) {
      textarea.value += '\n\n' + failedSearchRequests.terms.join('\n')

      form.querySelector('.mwf-next input').addEventListener('click', () => {
        Storage.removeLocalStorage('failedSearchRequests')
      })
    }
  }
}

const setMWFLabelsAndKeys = (field) => {
  const fieldLabelElements = field.getElementsByTagName('label')

  for (const fieldLabelElement of fieldLabelElements) {
    const fieldLabel = fieldLabelElement.textContent
    const fieldLabelForId = fieldLabelElement.getAttribute('for')
    const formElement = document.getElementById(fieldLabelForId)

    if (formElement) {
      const formElementKey = formElement.getAttribute('name')

      mwfLabelCollection.value.push({
        key: formElementKey,
        label: fieldLabel
      })
      mwfKeys.value.push(formElementKey)
    }
  }
}

const init = () => {
  containerId.value = props.config.selector.replace('#', '')

  document.addEventListener('mwf-ajax-finished', (context) => {
    let form = context.detail.$dest[0]

    htmlElement.value = document.querySelector('html')

    wrapFields()
    initHiddenFields(form)
    initRequired()
    initErrors()
    initEventFocusInput()
    initSelects()

    executeSwitch.value = true

    if (form.classList.contains('empty-search-form')) {
      initEmptySearchForm(form)
    }
  })

  document.addEventListener('mwf-fill-dropdown', (context) => {
    updateSelects(context.detail.$elem[0])
  })
}

onMounted(() => {
  window.cmpConsentType === 'consentmanager' ? (registerConsentListeners(checkVendorConsent), window.setTimeout(checkVendorConsent, 1000)) : checkConsent();
})

onUpdated(() => {
  if (window.jQuery && window.jQuery.mwfAjaxReplace) {
    window.jQuery.mwfAjaxReplace(props.config)
  }
})

onUnmounted(() => {
  window.removeEventListener('click', eventCloseCustomSelects)
})
</script>

<style module lang="scss">
@import './MondayWebforms.scss';
</style>
