<template>
  <div v-if="changeUrl.length > 0" :class="$style.container">
    <Modal :is-modal-open="isModalOpenRef" @close-modal="handleClose">
      <div :class="$style.content">
        <div :class="$style.title">
          {{ config.title }}
        </div>
        <p>{{ text }}</p>
        <div class="columns is-mobile">
          <div class="column">
            <Button colour="blue" :text="config.changeButtonText" is-link :href="changeUrl" />
          </div>
          <div :class="[$style.buttonRight, 'column']">
            <Button colour="white" :text="config.stayButtonText" is-close @close="handleClose" />
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script setup>
import axios from 'axios'
import { onBeforeMount, ref } from 'vue'
import * as Storage from '../../shared/Storage'
import { geoIPUrl } from '../../shared/globals'
import Button from '../Button/Button.vue'
import Modal from '../Modal/Modal.vue'

const props = defineProps({
  config: {
    type: Object,
    default: () => {}
  }
})

const GEOIP_COOKIE = 'hideGeoIpOverlayFor'

const countriesJSONRef = ref(null)
const isModalOpenRef = ref(false)
const cookieName = ref('')
const text = ref('')
const changeUrl = ref('')
const changeName = ref('')
const currentCountry = ref('')
const websiteCountryRef = ref('')

const initGeoIP = () => {
  axios
    .get(props.config.countriesJSON)
    .then((response) => ((countriesJSONRef.value = response.data.countries), getGeoIp()))
    .catch((error) => console.error(error))
}

const getGeoIp = () => {
  axios
    .get(window.geoIPUrl || geoIPUrl)
    .then((response) => checkGeoIp(response.data[0]))
    .catch((error) => {
      console.error(error)
    })
}

const checkGeoIp = (data) => {
  currentCountry.value = data.country_code

  if (
    countriesJSONRef.value !== null &&
    currentCountry.value &&
    currentCountry.value !== 'unknown' &&
    currentCountry.value !== websiteCountryRef.value &&
    currentCountry.value !== cookieName.value &&
    websiteCountryRef.value !== cookieName.value
  ) {
    Object.keys(countriesJSONRef.value).forEach((countryKey) => {
      if (countryKey === currentCountry.value) {
        const { url, name } = countriesJSONRef.value[countryKey]
        if (!url && props.config.corporateWebsiteUrl && !props.config.isCorporateWebsite) {
          changeUrl.value = props.config.corporateWebsiteUrl
          text.value = props.config.corporateText ? props.config.corporateText : props.config.text.replace(/{{countryName}}/gi, changeName.value)
        } else {
          changeUrl.value = url
          changeName.value = name
          text.value = props.config.text.replace(/{{countryName}}/gi, changeName.value)
        }
      } else {
        return
      }
    })
    if (!Object.prototype.hasOwnProperty.call(countriesJSONRef.value, currentCountry.value) && !!props.config.corporateWebsiteUrl && !props.config.isCorporateWebsite) {
      changeUrl.value = props.config.corporateWebsiteUrl
      text.value = props.config.corporateText ? props.config.corporateText : props.config.text.replace(/{{countryName}}/gi, changeName.value)
    }

    setTimeout(() => {
      isModalOpenRef.value = true
    }, 600)
  }
}

const handleClose = () => {
  Storage.setSessionStorage(GEOIP_COOKIE, websiteCountryRef.value)
  isModalOpenRef.value = false
}

onBeforeMount(() => {
  websiteCountryRef.value = props.config.websiteCountry
  cookieName.value = Storage.getSessionStorage(GEOIP_COOKIE)

  if (props.config.countriesJSON) {
    try {
      initGeoIP()
    } catch (error) {
      console.error(error)
    }
  }
})
</script>

<style module lang="scss">
@import './GeoIPOverlay.scss';
</style>
