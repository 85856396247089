import { createApp } from 'vue'
import { getVendorConsent, registerConsentListeners } from '../../globals/consentmanager'
import cmpVendorIDs from '../../globals/constants/cmpVendorIDs'
import { getMarketingConsent } from '../../globals/cookiebot'

export default function init() {
  const elements = document.querySelectorAll('.modalOverlay')

  const vendor = cmpVendorIDs.YOUTUBE

  if (elements) {
    elements.forEach((element) => {
      const app = createApp({
        data: function () {
          return {
            isVideo: true,
            modalActive: false,
            htmlElement: null,
            dictionary: window.dictionary,
            consentGiven:
              window.cmpConsentType === 'cookiebot'
                ? getMarketingConsent()
                : getVendorConsent(vendor.id)
          }
        },
        watch: {
          isModalOpen: function (newValue, oldValue) {
            if (newValue !== oldValue) {
              newValue === true ? this.openModal() : this.closeModal()
            }
          },
          modalActive: function () {
            this.consentGiven =
              window.cmpConsentType === 'cookiebot'
                ? getMarketingConsent()
                : getVendorConsent(vendor.id)
            window.setTimeout(this.checkConsent, 20)
          }
        },
        mounted() {
          registerConsentListeners(this.checkVendorConsent);
          window.addEventListener('keyup', this.escCloseModal)
          this.htmlElement = document.querySelector('html')
        },
        unmounted() {
          window.removeEventListener('keyup', this.escCloseModal)
        },
        methods: {
          handleContentButton() {
            if (window.cmpConsentType == 'consentmanager') {
              window.__cmp?.('setVendorConsent', [vendor.id, 1], () => {
                this.consentGiven = getVendorConsent(vendor.id)
                this.checkConsent()
              })
            } else {
              window.Cookiebot.onaccept = () => {
                this.consentGiven = getMarketingConsent()
                this.checkConsent()
              }
          }
        },
          checkVendorConsent() {
            this.consentGiven = getVendorConsent(vendor.id)
            this.checkConsent();
          },
          checkConsent() {
            const iframe = this.$refs.modalContent.querySelector('iframe')

            if (iframe && this.isVideo) {
              if (this.consentGiven && this.modalActive) {
                if (!iframe.src) {
                  iframe.src = iframe.getAttribute('data-src')
                  iframe.removeAttribute('data-src')
                }
              } else {
                if (iframe.src) {
                  iframe.setAttribute('data-src', iframe.src)
                  iframe.removeAttribute('src')
                }
              }
            }
          },
          openModal() {
            this.modalActive = true
            this.htmlElement.classList.add('is-clipped')
          },
          closeModal() {
            this.modalActive = false
            this.htmlElement.classList.remove('is-clipped')
            if (this.isVideo) {
              this.$refs.modalContent
                .querySelector('iframe')
                .contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*')
            }
          },
          internallyCloseModal() {
            this.closeModal()
            this.$emit('closeModal')
          },
          escCloseModal(event) {
            if (event.keyCode === 27) {
              this.internallyCloseModal()
            }
          }
        }
      })

      app.mount(`#${element.id}`)
    })
  }
}
