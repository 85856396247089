<template>
  <figure>
    <SlideLinkWrapper :slide="slide" @open-modal="handleClick">
      <i v-if="slide.isYouTube || slide.isVideo" class="fab fa-youtube" />
      <a v-if="!slide.linkText && slide.textLink"
        :href="slide.textLink"
        :target="slide.newWindow ? '_blank' : '_self'"
      >
        <img
          :src="slide.image"
          :alt="slide.title || ''"
          :width="slide.imageWidth"
          :height="slide.imageHeight"
        >
      </a>
      <img v-else
        :src="slide.image"
        :alt="slide.title || ''"
        :width="slide.imageWidth"
        :height="slide.imageHeight"
      />
    </SlideLinkWrapper>
    <!-- <a :href="slide.imageLink" :target="slide.newWindow ? '_blank' : '_self'" @click="handleClick">
    </a> -->
    <figcaption v-if="slide.title || slide.text" :class="$style.figCaption">
      <a v-if="!slide.linkText && slide.textLink"
      :href="slide.textLink"
      :target="slide.newWindow ? '_blank' : '_self'"
      >
        <h3 v-html="slide.title" />
      </a>
      <h3 v-else v-html="slide.title" />
      <div :class="$style.captionText" v-html="slide.text" />
      <div :class="$style.textLink">
        <a
          v-if="slide.linkText"
          :href="slide.textLink"
          :target="slide.newWindow ? '_blank' : '_self'"
        >
          {{ slide.linkText }}
          <i class="fas fa-chevron-right" />
        </a>
      </div>
    </figcaption>
  </figure>
</template>

<script setup>
import SlideLinkWrapper from './SlideLinkWrapper.vue';
const props = defineProps({
  slide: {
    type: Object,
    default: () => {}
  }
})

const emit = defineEmits(['openModal'])

const handleClick = () => {
  emit('openModal', props.slide.video, props.slide.isYouTube)
}
</script>

<style module lang="scss">
@import './RotatingCarousel.scss';
</style>
