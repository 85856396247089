<!-- eslint-disable-next-line vue/valid-template-root -->
<template />

<script setup>
import smoothscroll from 'smoothscroll-polyfill'
import 'svg-classlist-polyfill'
import { ref, onMounted, onUnmounted } from 'vue'

smoothscroll.polyfill()

const keys = ref({})

const filterProducts = (product) => {
  if (product) {
    const productFilter = new CustomEvent('updateFilter', {
      detail: { id: product }
    })

    window.dispatchEvent(productFilter)
  } else {
    const resetFilter = new CustomEvent('updateFilter', {
      detail: { id: 'all' }
    })

    window.dispatchEvent(resetFilter)
  }
}

const resetActiveLink = (parent, reset) => {
  const resetFilter = reset ? reset : false
  const elms = document.getElementById(parent).getElementsByClassName('BranchApp__svg-drop-active')

  for (let i = 0; i < elms.length; i++) {
    elms[i].classList.remove('BranchApp__svg-drop-active')
  }

  if (resetFilter) {
    filterProducts()
  }
}

const activateLink = (id, resultSize, randomId, section) => {
  const parent = 'BranchApp__svg-imagemap-' + randomId
  let scrollToId = 'BranchApp__products-' + randomId
  const reset = id === null

  resetActiveLink(parent, reset)

  if (resultSize != null) {
    document.getElementById('BranchApp__productCount-' + randomId).textContent =
      resultSize.toString()

    if (!reset) {
      filterProducts(id)
    }
  }

  if (section != null) {
    const sectionNodes = document.getElementsByClassName('BranchApp__section')
    for (let i = 0; i < sectionNodes.length; i++) {
      let sectionNode = sectionNodes[i]
      if (sectionNode.getAttribute('data-section') == section) {
        sectionNode.classList.remove('BranchApp__hide')
        initSvg()
        scrollToId = 'BranchApp__scroll-' + section
      } else {
        sectionNode.classList.add('BranchApp__hide')
      }
    }
  }

  const resetButton = document.querySelector('.BranchApp__svg-button-reset-' + randomId)

  if (resetButton) {
    resetButton.style.setProperty('display', reset ? 'none' : 'block', 'important')
  }

  if (!reset) {
    document
      .getElementById('BranchApp__svg-link-' + id)
      .getElementsByTagName('use')[0]
      .classList.add('BranchApp__svg-drop-active')
    if (resultSize != null || section != null) {
      document.getElementById(scrollToId).scrollIntoView({ behavior: 'smooth' })
    }
  }
}

const initActivateLink = () => {
  const linkNodes = document.getElementsByClassName('BranchApp__activateLink')
  for (let i = 0; i < linkNodes.length; i++) {
    const node = linkNodes[i]
    if (!node.classList.contains('activateLink-initialized')) {
      const activateLinkEvent = (e) => {
        const target = e.currentTarget

        e.preventDefault()

        const id = target.getAttribute('data-id') || null
        const resultSize = target.getAttribute('data-result-size') || null
        const randomId = target.getAttribute('data-random-id') || null
        const section = target.getAttribute('data-section') || null

        activateLink(id, resultSize, randomId, section)
      }
      node.addEventListener('click', activateLinkEvent, false)
      node.classList.add('activateLink-initialized')
    }
  }
}

const initSvg = () => {
  const supported = document.implementation.hasFeature(
    'www.http://w3.org/TR/SVG11/feature#Extensibility',
    '1.1'
  )
  const linkNodes = document.getElementsByClassName('BranchApp__svg-info')
  for (let i = 0; i < linkNodes.length; i++) {
    const nodeId = linkNodes[i].getAttribute('data-id')
    const pathNode = document.getElementById('BranchApp__svg-tt-path-' + nodeId)

    let pNodeH = 0
    if (supported) {
      pNodeH = document.getElementById('BranchApp__svg-p-' + nodeId).offsetHeight + 5
    } else {
      pNodeH =
        document.getElementById('BranchApp__old-browser-text-' + nodeId).getBBox().height + 10
    }
    const newPNodeY = -pNodeH - 45

    linkNodes[i].setAttribute('transform', 'matrix(1,0,0,1,-100,' + newPNodeY + ')')
    pathNode.setAttribute('d', 'M0 0 h200 v' + pNodeH + ' h-90 l-10 5 l-10 -5 h-90 Z')
  }

  const resizeNodes = document.getElementsByClassName('BranchApp__svg-resize')
  for (let i = 0; i < resizeNodes.length; i++) {
    const parentNodeId = resizeNodes[i].getAttribute('data-id')
    const parentNode = document.getElementById(parentNodeId)
    const h = parentNode.clientHeight
    const w = parentNode.clientWidth
    resizeNodes[i].setAttribute('viewBox', '0 0 ' + w + ' ' + h)
  }
}

const initScroll = () => {
  if ('querySelector' in document && 'addEventListener' in window) {
    const scrollLinks = document.getElementsByClassName('scroll')
    for (let i = 0; i < scrollLinks.length; i++) {
      if (!scrollLinks[i].classList.contains('scroll-is-initialized')) {
        const scrollLinkHref = scrollLinks[i].getAttribute('href')
        const scrollLinkTarget = document.querySelector(scrollLinkHref)
        if (scrollLinkTarget) {
          const scrollEvent = (e) => {
            let target = e.currentTarget

            e.preventDefault()

            const scrollTargetID = target.getAttribute('href')
            const scrollTarget = document.querySelector(scrollTargetID)
            if (scrollTarget) {
              scrollTarget.scrollIntoView({ behavior: 'smooth' })
            }
          }
          scrollLinks[i].addEventListener('click', scrollEvent, false)
          scrollLinks[i].classList.add('scroll-is-initialized')
        }
      }
    }
  }
}

const initTabs = () => {
  const tabs = document.getElementsByClassName('BranchApp__tabContainer')
  for (let i = 0; i < tabs.length; i++) {
    if (!tabs[i].classList.contains('tab-is-initialized')) {
      const activateSvg = () => initSvg()

      tabs[i].addEventListener('click', activateSvg, false)
      tabs[i].classList.add('tab-is-initialized')
    }
  }
}

onMounted(() => {
  /* used for disable user scrolling temporarily -> spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36 */
  keys.value = {
    37: 1,
    38: 1,
    39: 1,
    40: 1
  }
  initSvg()
  initActivateLink()
  initScroll()
  initTabs()
  window.addEventListener('resize', initSvg)
})

onUnmounted(() => {
  window.removeEventListener('resize', initSvg)
})
</script>

<style module lang="scss">
@import './BranchApp.scss';
</style>
