<template>
  <div>
    <div v-if="!consentGiven" :class="$style.consent">
      <h2>{{ dictionary.consentHeading }}</h2>
      <div v-html="vendorConsentText" />
      <Button
        is-close
        :class="$style.consentButton"
        :text="dictionary.consentButtonLabel"
        @close="handleContentButton"
      />
    </div>
    <template v-if="consentGiven">
  <div :class="$style.container">
    <Loading v-if="initializing" :size="100" background-color="#eee" />
    <div v-if="!initializing && !hideFilterPanel" :class="$style.filtercontainer">
      <MapFilter :groups="internalFilterGroups" @change="handleFilterChange" />
    </div>
    <div :class="[$style.contentContainer, { MapFilterApp__noFilter: hideFilterPanel }]">
      <div :class="$style.intro" v-html="state.introText" />
      <div v-if="!initializing" ref="mapContainer" :class="$style.mapcontainer">
        <Loading v-if="loading" :size="100" background-color="#eee" />
        <MapVue3
          :map-zoom="initialZoom"
          :markers="internalMarkers"
          :legend-items="state.legend"
          :use-form="config.useForm"
          :job-offer-j-s-o-n-u-r-l="config.jobOfferJSONURL"
          :filter-key-value="config.filterKeyValue"
          @loading-change="handleLoadingChange"
          @display-details="displayDetails"
        />
      </div>
    </div>
  </div>
    </template>
  </div>
</template>


<script setup>
import MapVue3 from '../MapVue3/MapVue3.vue'
import MapFilter from '../MapFilter/MapFilter.vue'
import Loading from '../Loading/Loading.vue'
import { onMounted, ref } from 'vue'
import Button from '../Button/Button.vue'
import { getVendorConsent, registerConsentListeners } from '../../globals/consentmanager'
import cmpVendorIDs from '../../globals/constants/cmpVendorIDs'

const vendor = cmpVendorIDs.GOOGLEMAPS
const dictionary = window.dictionary
const vendorConsentText = dictionary.consentText.replaceAll("###VENDOR_NAME###", vendor.name)

/*
 * * props
 */

const props = defineProps({
  state: {
    type: Object,
    default: () => {}
  },
  config: {
    type: Object,
    default: () => {}
  }
})

const checkVendorConsent = () => {
  consentGiven.value = getVendorConsent(vendor.id)
  checkConsent()
}

const handleContentButton = () => {
  if (window.cmpConsentType === 'consentmanager') {
    window.__cmp?.('setVendorConsent', [vendor.id, 1], () => {
      consentGiven.value = getVendorConsent(vendor.id)
      checkConsent()
    })
  } else {
    window.Cookiebot.onaccept = () => {
      consentGiven.value = true
      checkConsent()
    }
  }
}

const checkConsent = () => {
  if (consentGiven.value) {
    init()
  }
}

/*
 * * emits
 */

const emit = defineEmits(['displayDetails'])

/*
 * * refs
 */

const consentGiven = ref(window.Cookiebot ? true : getVendorConsent(vendor.id))
const initializing = ref(true)
const initialZoom = ref(props.state.initialZoom)
const internalMarkers = ref([])
const internalFilterGroups = ref([])
const loading = ref(true)
const mapContainer = ref(null)
const hideFilterPanel = ref(
  props.config.noFilter || !(props.state.filterGroups && props.state.filterGroups.length)
)

/*
 * * methods
 */

const handleLoadingChange = (value) => {
  loading.value = value
}

const handleFilterChange = (filterGroupId, itemId, itemActive) => {
  const foundGroup = internalFilterGroups.value.filter(
    (filterGroup) => filterGroup.id === filterGroupId
  )[0]

  const foundFilter = foundGroup.items.filter((item) => item.id === itemId)[0]

  foundFilter.active = itemActive

  setInternalMarker()

  if (window.matchMedia('(max-width: 767px)').matches) {
    mapContainer.value.scrollIntoView({
      behavior: 'smooth',
      block: 'end'
    })
  }
}

const setInternalMarker = () => {
  const markers = props.state.contacts.filter((marker) => {
    let foundInGroups = 0
    internalFilterGroups.value.forEach((filterGroup) => {
      let foundInGroup = 0
      const markerFilterGroup = marker.filterGroups[filterGroup.id]
      filterGroup.items.forEach((f) => {
        if (f.active) {
          if (markerFilterGroup.indexOf(f.id) !== -1) {
            foundInGroup++
          }
        }
      })

      foundInGroup > 0 && foundInGroups++
    })

    return foundInGroups === internalFilterGroups.value.length
  })

  internalMarkers.value = markers
}

const setAllFilterActive = () => {
  internalFilterGroups.value = props.state.filterGroups.map((group) => ({
    ...group,
    items: group.items.map((item) => ({
      ...item,
      active: true
    }))
  }))
}

const init = () => {
  if (!hideFilterPanel.value) {
    setAllFilterActive()
  }
  setInternalMarker()
  initializing.value = false
}

const displayDetails = (url) => {
  emit('displayDetails', url)
}

/*
 * * lifecycle methods
 */

onMounted(() => {
  window.cmpConsentType === 'consentmanager' ? (registerConsentListeners(checkVendorConsent), window.setTimeout(checkVendorConsent, 1000)) : checkConsent();
})
</script>

<style module lang="scss">
@import './MapFilterApp.scss';
</style>
